<template lang="pug">
div
  .costs-list-subtitle.m-t-40
      strong {{ customStyle.period_title }}:
  .preloader-behind
    .preloader-behind-row.costs-row.m-t-15
      div(v-for="(fixedCost, index) in fixedCostsBy" :key="index")
        input.radio-block-input(
          type="radio"
          data-vv-name="month"
          v-validate="'required'"
          :checked="month === fixedCost.months"
          :id="`month${index}`"
          :value="fixedCost.months"
          @change.stop="setVehicleCostId(fixedCost)"
        )
        label.radio-block(:for="`month${index}`")
          .radio-block-title {{fixedCost.months}} {{ $t('months') }}
          div(v-if="showPices")
            .radio-block-info(v-if="index === 0") {{ $t("steps.costs.standard") }}
            .radio-block-info.red-text(v-else)
              | + {{fixedCost.general_price - fixedCostsBy[0].general_price}} {{ currencySymbol }}/{{ $t('mon') }} <br>

    small.text-danger {{ errors.first('getDefaults[getPriceForm][month]:required') }}
</template>

<script>
import {mapState, mapGetters, mapActions,} from 'vuex'

export default {
  props: {
    fixedCostsBy: Array,
    showPices: Boolean,
  },
  computed: {
    ...mapState('product', [ 'vehicle', 'priceForm' ]),
    ...mapState('order', ['residual', 'cashPayment']),
    ...mapState('reseller', {
      customStyle: state => state.customStyle.firstStep || {},
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
    ...mapGetters('product', [ 'getDefaults', 'getPriceForm', ]),
    ...mapGetters('order', [ 'vehicleCostId', ]),
    vehicleCostId: {
      get() {
        return this.$store.state.order.vehicleCostId
      },
      set(value) {
        this.$store.commit('order/setVehicleCostId', value)
      }
    },
    month() {
      return this.getDefaults[this.getPriceForm]['months'];
    },
    distance() {
      return this.getDefaults[this.getPriceForm]['distance'];
    }
  },
  methods: {
    ...mapActions('product', ['getFixedCostByMonthAndDistance', 'updateDefaults', ]),
    setVehicleCostId(fixedCost) {
      const {id, months, residual, } = fixedCost;
      const _this = this;

      this.vehicleCostId = id;

      this.getFixedCostByMonthAndDistance([months, this.getDefaults[this.getPriceForm]['distance']])
        .then((cost) => {
          this.$store.dispatch('order/saveAllFields', {
            'vehicleCostId': cost.id,
            'totalMonthlyPrice': cost.general_price,
            'residual': residual,
          })
          
          this.$store.dispatch('product/updateVehicle', {
            'vehicleCostId': cost.id,
            'residual': residual,
          });

          _this.updateDefaults({
            form: _this.getPriceForm,
            months: months,
            id: cost.id,
            residual: residual,
          })
        })
    },
  },
}
</script>